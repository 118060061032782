require("prismjs/themes/prism-solarizedlight.css")

const { getBrowserLanguage } = require("ptz-i18n")
const { navigate } = require("gatsby")

// exports.onInitialClientRender = () => {
//   const lang = getLang()
//   navigate(getPathname(lang))
// }

exports.onPreRouteUpdate = () => {
  const lang = getLang()
  navigate(getPathname(lang))
}

function getLang() {
  let lang = localStorage.getItem("lang") || getBrowserLanguage()
  return lang && lang.length ? lang.split("-")[0] : "en"
}

function getPathname(lang) {
  const pathnameArr = location.pathname.split("/")
  if (lang === "zh") {
    pathnameArr[1] =
      pathnameArr[1] === "zh" ? pathnameArr[1] : "zh/" + pathnameArr[1]
  }
  return pathnameArr.join("/") + location.hash
}
