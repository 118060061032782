// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-post-js": () => import("./../../../src/components/post.js" /* webpackChunkName: "component---src-components-post-js" */),
  "component---src-pages-404-en-js": () => import("./../../../src/pages/404.en.js" /* webpackChunkName: "component---src-pages-404-en-js" */),
  "component---src-pages-404-zh-js": () => import("./../../../src/pages/404.zh.js" /* webpackChunkName: "component---src-pages-404-zh-js" */),
  "component---src-pages-index-en-js": () => import("./../../../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-zh-js": () => import("./../../../src/pages/index.zh.js" /* webpackChunkName: "component---src-pages-index-zh-js" */),
  "component---src-pages-topics-en-js": () => import("./../../../src/pages/topics.en.js" /* webpackChunkName: "component---src-pages-topics-en-js" */),
  "component---src-pages-topics-zh-js": () => import("./../../../src/pages/topics.zh.js" /* webpackChunkName: "component---src-pages-topics-zh-js" */)
}

